import React from "react"
import PropTypes from "prop-types"
import SbEditable from "storyblok-react"
import Helmet from "react-helmet"
import StoryblokComponentResolver from "./StoryblokComponentResolver"
import Layout from "../components/Layout"
import Nav from "../components/Nav"

const GeneralPage = ({ blok, slug }) => {
  const { body } = blok

  return (
    <SbEditable content={blok}>
      <Helmet>
        <body className={`general_page ${slug}`} />
      </Helmet>
      <header className="mt-12 mb-10">
        <Nav currentPage="general_page" />
      </header>
      <Layout slim>
        {body && <StoryblokComponentResolver content={body} />}
      </Layout>
    </SbEditable>
  )
}

GeneralPage.propTypes = {
  blok: PropTypes.shape({
    body: PropTypes.object,
  }),
  slug: PropTypes.string,
}

export default GeneralPage
