import React from "react"
import ReactDOMServer from "react-dom/server"
import PropTypes from "prop-types"
import ReactHtmlParser from "react-html-parser"
import StoryblokClient from "storyblok-js-client"
import Components from "../components/Components"

let Storyblok = new StoryblokClient({
  accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
})

// Resolve any components from body bloks (rich text content in Storyblok)
Storyblok.setComponentResolver((component, blok) => {
  return ReactDOMServer.renderToStaticMarkup(
    React.createElement(Components(component), {
      key: blok._uid,
      blok: blok,
    })
  )
})

const StoryblokComponentResolver = ({ content }) => {
  return ReactHtmlParser(Storyblok.richTextResolver.render(content))
}

StoryblokComponentResolver.propTypes = {
  content: PropTypes.object.isRequired,
}

export default StoryblokComponentResolver
