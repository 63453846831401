import React from "react"
import PropTypes from "prop-types"
import Components from "./Components"
import FormLoader from "./FormLoader"

const Grid = ({ blok }) => {
  const { block } = blok

  return (
    <div className="grid_block flex flex-col md:flex-row">
      {block.map((item, index) => {
        // Don't add a gutter to the last item
        const gutter =
          index !== block.length - 1 ? "mb-8 md:mb-0 mr-0 md:mr-8" : ""

        switch (item.component) {
          case "form":
            return <FormLoader key={item._uid} blok={item} className={gutter} />
          default:
            return React.createElement(Components(item.component), {
              key: item._uid,
              blok: item,
              className: gutter,
            })
        }
      })}
    </div>
  )
}

Grid.propTypes = {
  blok: PropTypes.shape({
    block: PropTypes.array.isRequired,
  }),
}

export default Grid
