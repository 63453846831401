import React from "react"
import cn from "classnames"
import PropTypes from "prop-types"
import StoryblokComponentResolver from "./StoryblokComponentResolver"

const Content = ({ blok, className }) => {
  const { body, title } = blok

  return (
    <div className={cn("content", className)}>
      {title && <h2>{title}</h2>}
      {body && <StoryblokComponentResolver content={body} />}
    </div>
  )
}

Content.propTypes = {
  blok: PropTypes.shape({
    body: PropTypes.object,
    title: PropTypes.string,
  }),
}

export default Content
