import React from "react"
import PropTypes from "prop-types"
import { LetsChatForm } from "./forms"
import ComponentNotFound from "../components/ComponentNotFound"

const FormLoader = ({ blok, className }) => {
  switch (blok.form_type) {
    case "lets_chat":
      return <LetsChatForm blok={blok} className={className} />
    default:
      return <ComponentNotFound />
  }
}

FormLoader.propTypes = {
  blok: PropTypes.shape({
    form_type: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
}

export default FormLoader
