import React from "react"
import cn from "classnames"
import PropTypes from "prop-types"
import { imageUrlResolver } from "../helpers"
import styles from "./QuoteImageBlock.module.css"

// Options to send for Storyblok's image service
const imageOptions = "576x576"

const QuoteImageBlock = ({ blok }) => {
  const { image, quote, source } = blok

  return (
    <aside
      className={cn(
        styles.quote_container,
        "bg-brandBlue flex justify-around mb-16"
      )}
    >
      <div className="w-1/2">
        <img src={imageUrlResolver(image, imageOptions)} alt="Lori Farnum" />
      </div>
      <div
        className={cn(
          styles.blockquote_container,
          "w-1/2 flex flex-col justify-center items-center mb-0 px-2 xs:px-8"
        )}
      >
        <blockquote>
          <h2 className="text-white text-sm xs:text-xl md:text-3xl lg:text-6xl text-center font-bold">
            {quote}
          </h2>
          <h6 className="uppercase text-white text-center text-xs font-hairline md:text-xl mb-0">
            {`- ${source}`}
          </h6>
        </blockquote>
      </div>
    </aside>
  )
}

QuoteImageBlock.propTypes = {
  blok: PropTypes.shape({
    image: PropTypes.string,
    quote: PropTypes.string,
    source: PropTypes.string,
  }),
}

export default QuoteImageBlock
