import React from "react"
import cn from "classnames"
import PropTypes from "prop-types"
import styles from "./LetsChatForm.module.css"

const LetsChatForm = ({ blok, className }) => {
  const { title, width } = blok

  return (
    <div
      className={cn(
        "lets-chat-form",
        width ? `flex-shrink-0 ${width}` : "",
        className
      )}
    >
      {title && <h2 className="text-center text-brandBlue">{title}</h2>}
      <form
        className={cn(styles.form)}
        name="contact"
        method="post"
        action="/thank-you"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <p>
          <label className="block mb-2">Name:</label>
          <input type="text" name="name" />
        </p>
        <p>
          <label className="block mb-2">Email:</label>
          <input type="email" name="email" />
        </p>
        <p>
          <label className="block mb-2">Message:</label>
          <textarea name="message"></textarea>
        </p>
        <p>
          <button
            className="px-6 py-3 text-white bg-brandBlue hover:bg-blue-300 hover:text-blue-900 rounded-sm"
            type="submit"
          >
            Submit
          </button>
        </p>
      </form>
    </div>
  )
}

LetsChatForm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
}

export default LetsChatForm
