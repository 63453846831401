import React from "react"
import PropTypes from "prop-types"
import SbEditable from "storyblok-react"

const Image = ({ blok, slug }) => {
  const { image, image_alt, caption } = blok
  const getImageBlock = () => (
    <figure>
      <img src={image} alt={image_alt} className="mx-auto" />
      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  )

  return (
    <SbEditable content={blok}>
      <div className="image">
        {slug ? <a href={slug}>{getImageBlock()}</a> : getImageBlock()}
      </div>
    </SbEditable>
  )
}

Image.propTypes = {
  blok: PropTypes.shape({
    image: PropTypes.string,
    image_alt: PropTypes.string,
    caption: PropTypes.string,
  }),
  slug: PropTypes.string,
}

export default Image
